import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Adalina",
  pageDescription:
    "An upscale, modernized Italian restaurant finds tech to match",
  title: "Chef-driven, Enhanced by Tech",
  subTitle: `See how Adalina and SpotOn forged a true partnership through tech.`,
  businessName: "Adalina",
  industry: "Restaurants, fine dining",
  location: "Chicago, IL",
  products: [spotonProducts.LABOR_MANAGEMENT, spotonProducts.RESTAURANT],
  description:
    "From the back to the front-of-house, the Adalina team is committed to delivering an excellent guest experience. It’s in the warm, attentive service, the thoughtful decor, and playfully current menu. But to win the guest experience, Adalina needs a solid tech partner behind the scenes.",
  quoteData: {
    imgName: "adalina.png",
    quote: `SpotOn offers a true partnership. They care about your input, they care about servicing you as a customer and a person in the business of service. Anybody willing to put that much effort into customer service is a winning combination for us.”`,
    personName: "Jonathan Gillespie",
    personTitle: "Partner, Adalina",
  },
  goal: `Adalina needed a multifaceted solution to make team management more efficient, streamline the ordering process, and provide in-depth business insights. In a shifting compliance landscape, Adalina wanted to remain ahead of the curve, while providing their team with more shchedule visibility and flexibility.`,
  solution: `Adalina’s team, which ranges in tech savviness, adapted quickly to SpotOn’s intuitive point-of-sale. With SpotOn Teamwork, Adalina’s general manager saves time on schedule changes and staffs shifts more efficiently, to the benefit of everyone’s bottom line. Detailed, accessible reporting helps Adalina’s operators set relevant KPIs right away—and create schedules that help meet them, too.`,
  //   solutionReadMore: {
  //     text: `Read their story.`,
  //     url: `https://www.spoton.com/blog/gather-around-at-urban-roast/`,
  //   },
  results: {
    title: `The results`,
    stats: [
      {
        title: `$500 - $800`,
        description: `Weekly labor savings`,
      },
      {
        title: `1 hour`,
        description: `Per week saved on clock-in review`,
      },
      {
        title: `Up to 2 days`,
        description: `Faster schedule approval`,
      },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Fewer spreadsheets. More teamwork",
    imageName: "guisto-newport.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-12-02",
    href: "/case-studies/guisto-newport",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
    ],
  },
  {
    title: "Controlling costs with confidence",
    imageName: "tinys-and-the-bar-upstairs.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-11-01",
    href: "/case-studies/tinys-and-the-bar-upstairs",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
  {
    title: "Hot & ready without the hassle",
    imageName: "callies-hot-little-biscuit.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-10-05",
    href: "/case-studies/callies-hot-little-biscuit",
    goals: [
      resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
];
